import { PropsWithChildren } from 'react';
import { Box, Stack, Paper, Group, Text, useMantineTheme } from '@mantine/core';
import logoCrudoc from '@/assets/img/logo-crudoc.svg';

import { useMediaQuery } from '@mantine/hooks';
import { useStyles } from './styles';
import backgroundImage from '@assets/img/bg-saas.jpg';

interface WraperProps {
  text?: string | null;
  title?: string | null;
}

export const LoginWraper = (props: PropsWithChildren<WraperProps>) => {
  const { title, text, children } = props;

  const isMobile = useMediaQuery('(max-width: 576px)');
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const defaultBackground = theme.colors.gray[1];
  const imageBackground = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed'
  };

  return (
    <Box
      sx={{
        backgroundColor: defaultBackground,
        // ...(isSaaS(Settings) ? imageBackground : { backgroundColor: defaultBackground }),
        padding: `0 20px`
      }}
    >
      <Stack
        align="center"
        justify="center"
        style={{ height: 'auto', minHeight: '100vh' }}
        className={isMobile ? classes.pageHeight : ''}
      >
        <Box w={540} sx={{ maxWidth: 'calc(100% - 10px)' }} className={classes.containerBox}>
          <Group className={classes.containerLogo}>
            <img
              src={logoCrudoc}
              className={classes.logo}
            />
          </Group>
          <Paper className={classes.box} shadow="0 2px 8px 0 rgba(16, 24, 40, 0.1)" radius="8px">
            <Stack gap="xl">
              <Stack gap="xs">
                {title && (
                  <Text fz={32} fw={500} className={classes.title}>
                    {title}
                  </Text>
                )}
                {text && <Text c="dimmed">{text}</Text>}
                {children}
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>
    </Box>
  );
};
