import { IDepartment } from './../directories/types';
import { IRole } from '../directories/types';
import { IPasswordSecurity } from '@/entities/admin-app/filials';

export interface IQueryLogin {
  Login: string;
  Password: string;
  SystemName?: string;
  DeviceId?: string;
  LoginAs?: string;
  CryptedPassword?: string;
  CustomerCode?: string;
}

export interface ITokens {
  AuthToken: string;
  RefreshToken?: string;
  UserId?: number;
  ExpiredAt?: Date;
  User?: any;
  Id: number;
}

export interface ICustomer {
  Id: number;
  Status: number;
  Title: string;
  Company: string;
  Description: string;
  CreatedAt: Date;
}

export interface IUser {
  Id: number;
  Login: string;
  Name: string;
  Surname: string;
  FirstName: string;
  MiddleName: string;
  Company?: any;
  Department?: IDepartment | null;
  Position?: any;
  Jid: string;
  BirthDate?: any;
  PhoneNumber: string;
  MobilePhone?: any;
  WorkPhone?: any;
  Email: string;
  NotApproved: any[];
  About: string;
  Modified: Date;
  ChatContactId: number;
  Type: number;
  Status: number;
  Roles: IRole[];
  RoleTypes: number[] | string[];
  Group?: any;
  Interests?: any;
  City?: any;
  Customer: ICustomer;
  Avatar?: string;
  NewPassword?: string;
  OldPassword?: string;
  HasAvatar?: boolean;
  AvatarKey?: string;
}

// TODO Изменить нейминг
export interface IUserData {
  Tokens: ITokens;
  State: string;
  User: IUser;
  Settings: IGlobalSettings;
}

export interface IUpdateUserData {
  User: IUser;
  OldPassword?: string;
  NewPassword?: string;
}

export enum IAuthTypeEnum {
  ESIA = 'esia',
  GASU = 'gasu',
  SAML2 = 'saml2',
  KERBEROS = 'kerberos'
}
export interface ITimezone {
  CommonId: number;
  Id: number;
  Name: string;
  SecondEnabled: boolean;
  SecondId: number;
  SecondTimezoneName: string;
}
export interface IGlobalSettings {
  UserId: number;
  ModuleSettings?: ModuleSettings;
  AppType?: string;
  AuthType?: string;
  IsCrypt?: boolean;
  Timezone: ITimezone;
}

export interface ModuleSettings {
  ModulesAccess: ModuleAccess[];
  ModuleUrls: ModuleUrl[];
}

export interface ModuleUrl {
  Name: string;
  Url: string;
  Title: string;
  Icon: string;
  HasAccess: boolean;
  ShowItem: boolean;
}

export type ModuleAccess =
  | 'Mail'
  | 'Calendar'
  | 'Disk'
  | 'Admin'
  | 'Draw'
  | 'Payments'
  | 'Contacts'
  | 'Projects'
  | 'Pages';

export interface IBulkForgot {
  results: {
    id: number;
    isSuccess: boolean;
  };
}

export enum ValidateFieldEnum {
  Login = 0,
  Email = 1,
  Phone = 2,
  LoginAndEmail = 3
}

export interface IUserExistsQuery {
  email: string;
}

export interface IUserExistsResponse {
  ExistsUser: boolean;
}

export interface ICanCreateUserResponse {
  IsCanAddUser: boolean;
}

export interface ICheckExistFieldRequest {
  field: string;
  existField: ValidateFieldEnum;
  userId?: number;
}

export interface ICheckExistFieldResponse {
  ExistsUser: boolean;
}
export interface ICheckExistCodeResponse extends IPasswordSecurity {
  IsActive: boolean;
}
export interface ICheckExistRegisterCodeResponse extends IPasswordSecurity {
  IsActive: boolean;
  Company: string;
  CustomerId: number;
}
export interface ICheckExistCodeRequest {
  Code: string;
}
export interface IResetPasswordRequest {
  UserId: number;
}
export interface IResetPasswordResponse {
  Url: string;
}

export enum IUpdatePasswordWithCodeEnum {
  Changed = 0,
  ExpiredLink = 1 // не валидная ссылка
}
export interface IUpdatePasswordWithCodeRequest {
  UserPassword: string;
  CryptedPassword?: string;
  Code: string;
}
export interface IUpdatePasswordWithCodeResponse {
  Status: IUpdatePasswordWithCodeEnum;
}
