import { IApiTimeZones, TimeZones } from '@/entities/admin-app/commonSettings';
import { DateTime } from 'luxon';
import { t } from 'i18next';

export const transformTimeZones = (data: IApiTimeZones[]) => {
  const timeZones: TimeZones = {};
  const browserUtcId = 0;

  data.forEach((timeZone, index) => {
    const dt = DateTime.now().setZone(timeZone.TzIdName);
    const offsetInMinutes = dt.offset;

    const sign = offsetInMinutes >= 0 ? '+' : '-';
    const absOffset = Math.abs(offsetInMinutes);
    const hours = Math.floor(absOffset / 60);
    const minutes = absOffset % 60;

    const offsetFormatted = `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}`;
    timeZones[timeZone.Id || index] = {
      value: String(timeZone.Id || index),
      label: `(${offsetFormatted}) ${t(`timezones.${timeZone.TzIdName}`)}`,
      utc: timeZone.UtcOffset,
      utcOffsetMinutes: timeZone.UtcOffsetMinutes,
      tzName: timeZone.TzIdName
    };
  });

  return { timeZones, browserUtcId };
};
