import { ITokens, IUser } from '../admin-app/auth';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { userInfoActions } from '../admin-app/user';
import i18n from '@/entities/i18next';
import { universalNotification } from '@/lib/utils/notification';

const statusErrorExceptions: Record<number, string[]> = {
  406: [
    'changePasswordLinkedUserEmail',
    'commonSettings',
    'getFilialsInfoCommon',
    'changeStatuses',
    'removeUsers',
    'deleteFilials',
    'resetPassword',
    'updateUserInfo',
    'getFilialsInfoCommon',
    'getFilialQuota',
    'getModuleRoles',
    'getModuleUsers'
  ],
  429: ['login'],
  500: ['add', 'ldapSettingsSync', 'setQuotaByRole'],
  504: ['ldapSettingsSync']
};
export const errorHandlingMiddleware: Middleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload, meta } = action;
    const { status, originalStatus } = payload;

    const {
      arg: { originalArgs }
    } = meta;

    const isValidStatus = (status: any) => {
      return typeof status === 'number';
    };

    const validStatus = isValidStatus(status)
      ? status
      : isValidStatus(originalStatus)
      ? originalStatus
      : null;

    switch (validStatus) {
      case 401:
        if (!originalArgs?.LoginAs) {
          store.dispatch(userInfoActions.setUserTokens({} as ITokens));
          store.dispatch(userInfoActions.setUserInfo({} as IUser));
          universalNotification({
            error: payload,
            errorTitle: i18n.t('login.errors.loginError'),
            errorMessage: i18n.t('login.errors.incorrectNamePassword'),
            isError: true,
            isSuccess: false
          });
        }
        break;
      case 403:
        universalNotification({
          error: payload,
          errorTitle: i18n.t('login.errors.blockedAccount'),
          isError: true,
          isSuccess: false
        });
        break;
      case 406:
        if (!statusErrorExceptions[406].includes(action.meta.arg.endpointName)) {
          universalNotification({
            error: payload,
            errorTitle: i18n.t('login.errors.incorrectNamePassword'),
            isError: true,
            isSuccess: false
          });
        }
        break;
      case 429:
        if (statusErrorExceptions[429].includes(action.meta.arg.endpointName)) {
          universalNotification({
            error: payload,
            errorTitle: i18n.t('login.errors.toManyRequests'),
            isError: true,
            isSuccess: false
          });
        } else if (action.meta.arg.endpointName !== 'loginAs') {
          universalNotification({
            error: payload,
            isError: true,
            isSuccess: false
          });
        }
        break;
      case 404:
        universalNotification({
          error: payload,
          errorTitle: i18n.t('login.errors.notFound'),
          isError: true,
          isSuccess: false
        });
        break;
      case 400:
        // case 500:
        //   if (action.meta.arg.endpointName !== 'resetPassword') {
        //     showNotification({
        //       title: `Ошибка ${status}`,
        //       message: data?.ErrorMessage ?? data?.Response?.Data,
        //       autoClose: 10_000,
        //       color: 'red'
        //     });
        //   }
        break;
      case 500:
        if (!statusErrorExceptions[500].includes(action.meta.arg.endpointName)) {
          universalNotification({
            error: payload,
            errorTitle: i18n.t('login.errors.internalError'),
            errorMessage: i18n.t('login.errors.tryLater'),
            isError: true,
            isSuccess: false
          });
        }
        break;
      case 502:
        universalNotification({
          error: payload,
          errorTitle: i18n.t('requestsErrors.badGateway'),
          errorMessage: i18n.t('login.errors.tryLater'),
          isError: true,
          isSuccess: false
        });
        break;
      case 504:
        if (!statusErrorExceptions[504].includes(action.meta.arg.endpointName)) {
          universalNotification({
            error: payload,
            errorTitle: i18n.t('errors.serverNoResponse'),
            errorMessage: i18n.t('login.errors.tryLater'),
            isError: true,
            isSuccess: false
          });
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};
