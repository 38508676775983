import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles(() => ({
  container: {
    backgroundColor: '#F5F6F8'
  },
  root: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    marginTop: HEADER_HEIGHT,
    width: '100%',
    display: 'flex'
  },
  user_box: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F6F8',
    margin: 'auto',
    paddingTop: '20px',
    width: 'auto',
    maxWidth: '1000px',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  user_box_avatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '320px',
    marginBottom: '36px'
  },
  menu: {
    display: 'flex!important' as any,
    backgroundColor: 'inehrit',
    marginTop: '30px',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  menu_item: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    size: '16px',
    width: '180px',
    height: '100px',
    margin: '8px',
    '@media (max-width: 760px)': {
      width: '100%',
      maxWidth: '180px'
    },
    '& .mantine-Menu-itemIcon': {
      marginRight: '0'
    },
    '& .mantine-Menu-itemSection': {
      margin: 0
    },
    '&[data-hovered]': {
      backgroundColor: '#FFFFFF'
    }
  },
  user_box_buttons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '15px',

    '& button': {
      height: '42px',
      fontWeight: 'normal',
      fontSize: '16px'
    }
  }
}));
