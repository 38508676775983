import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((_, __, utils) => ({
  container: {
    backgroundColor: 'white',
    padding: '20px 24px 24px 24px',
    borderRadius: '16px',
    border: '1px solid #F1F3F5'
  },

  fixedWidth: {
    width: '340px',
    [utils.smallerThan('xs')]: {
      width: '100%'
    }
  }
}));
