import { ErrorBoundary } from '@components/error-boundary';
import { useAppSelector } from '@/hooks/redux/redux';
import CommonModuleContainer from '@/containers/pages/filials/edit/components/common/modules';
import { ModulesEnum } from '@/types/enums/filials';

const PagesModule = () => {
  const { filial } = useAppSelector((state) => state.filials);

  return (
    <ErrorBoundary componentTitle="Pages module page">
      <CommonModuleContainer filial={filial} currentModule={ModulesEnum.Pages} />
    </ErrorBoundary>
  );
};

export default PagesModule;
