import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  scrolled: {
    boxShadow: theme.shadows.sm
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  error: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    '& p': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px 10px'
    }
  },

  selectInput: {
    cursor: 'pointer',
    '& .mantine-Input-input': {
      borderRadius: '8px'
    }
  },

  options: {
    '& .mantine-Combobox-option': {
      '&[data-combobox-active=true]': {
        backgroundColor: theme.colors.blue[5],
        color: theme.white
      }
    }
  }
}));
