import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import { formatDateTime } from '@/lib/utils/date';
import { numbersToActionType } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { IAuditColumnsVisibilityType } from '@/containers/pages/statistics/types';
import { Description } from '@/containers/pages/statistics/components/tabs/audit/components/table/descriptionColumn';
import { Box } from '@mantine/core';
import OverflowTip from '@components/OverflowTip';
import { User } from '../components/table/userColumn';
// import { AuthActionsDropdown } from '@/containers/pages/statistics/components/tabs/audit/components/table/action';

export const useGetColumns = (columnVisibility: IAuditColumnsVisibilityType) => {
  const { t } = useTranslation();
  const columnsData: IColumnData[] = [
    {
      width: 'auto',
      dataIndex: 'Name',
      title: t('statistics.user'),
      hideColumn: columnVisibility['UserName'],
      render: (item: IRowData) => <User UserName={item?.UserName} UserId={item?.UserId} />
    },
    {
      width: 'auto',
      dataIndex: 'Date',
      title: t('statistics.date_time'),
      hideColumn: columnVisibility['Date'],
      render: (item: IRowData) => formatDateTime(item?.Date)
    },
    {
      width: 'auto',
      dataIndex: 'ActionType',
      title: t('statistics.action'),
      hideColumn: columnVisibility['ActionType'],
      render: (item: IRowData) => numbersToActionType({ id: item?.Type, t })
    },
    {
      width: 'auto',
      dataIndex: 'Description',
      title: t('statistics.description'),
      hideColumn: columnVisibility['Description'],
      render: (item: IRowData) => (
        <Description DescriptionTitle={item?.Description} Titles={item?.Titles} />
      )
    },
    {
      width: 'auto',
      dataIndex: 'Ip',
      title: t('statistics.ip'),
      hideColumn: columnVisibility['Ip'],
      render: (item: IRowData) => (item?.Ip ? item?.Ip : t('noData'))
    },
    {
      width: 'auto',
      dataIndex: 'NewValue',
      title: t('statistics.newValue'),
      hideColumn: columnVisibility['NewValue'],
      render: (item: IRowData) => (
        <Box maw={300}>
          <OverflowTip>{item?.NewValue ? item?.NewValue : t('noData')}</OverflowTip>
        </Box>
      )
    },
    {
      width: 'auto',
      dataIndex: 'OldValue',
      title: t('statistics.oldValue'),
      hideColumn: columnVisibility['OldValue'],
      render: (item: IRowData) => (
        <Box maw={300}>
          <OverflowTip>{item?.OldValue ? item?.OldValue : t('noData')}</OverflowTip>
        </Box>
      )
    }
    // {
    //   width: '100px',
    //   dataIndex: 'Action',
    //   title: '',
    //   hideColumn: false,
    //   render: (item: IRowData) => <AuthActionsDropdown item={item} />
    // }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
