import { Badge, Box, Flex, Group, Text } from '@mantine/core';
import { useStyles } from './styles';
import { TablerIcon } from '@tabler/icons';
import { FC, ReactNode } from 'react';

interface ICardContainerProps {
  sectionName: string;
  Icon: TablerIcon;
  children: ReactNode;
  counter?: number;
  subscriptionPage?: boolean;
}

export const CardContainer: FC<ICardContainerProps> = ({
  sectionName,
  Icon,
  children,
  counter,
  subscriptionPage
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.container, {
        [classes.fixedWidth]: subscriptionPage
      })}
      data-name="card-container"
    >
      <Flex justify="space-between" align="center" mb="18px">
        <Group gap="12px">
          <Text fz="lg" fw="500">
            {sectionName}
          </Text>
          {counter ? (
            <Badge
              classNames={{ root: classes.badgeRoot, label: classes.badgeInner }}
              variant="filled"
            >
              {counter}
            </Badge>
          ) : null}
        </Group>
        <Icon size={30} color="#A4B0C2" strokeWidth="1.5" />
      </Flex>
      {children}
    </Box>
  );
};
