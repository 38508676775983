import { FC, ReactNode } from 'react';
import { Group, Box, Burger, Paper, Transition } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useAppSelector } from '@hooks/redux/redux';
import { isAdmin, isSaaS } from '@/lib/utils/access';
import logoCrudoc from '@/assets/img/logo-crudoc.svg';
// import logoSaas from '@/assets/img/saas-black.svg';
// import logoOffice from '@/assets/logo-admin.svg';

import { useStyles } from './styles';
import { Link, useLocation } from 'react-router-dom';
import { HEADER_HEIGHT } from './constants';

export interface IHeaderList {
  label: string;
  link: string;
  active: boolean;
}

interface IMainHeaderProps {
  list: IHeaderList[];
  height?: string | number;
  rightSection?: ReactNode;
  dashboardPage?: boolean;
}

export const MainHeader: FC<IMainHeaderProps> = ({
  list,
  height = HEADER_HEIGHT,
  rightSection,
  dashboardPage
}) => {
  const location = useLocation();

  const { Settings } = useAppSelector((state) => state.userInfo);
  const currentUser = useAppSelector((state) => state.userInfo.User);

  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();

  const mainItems = list.map((item) => (
    <Link
      to={item.link}
      data-link={item.link}
      data-cy={`${item.link.slice(1)}Tab`}
      key={item.label}
      className={cx(classes.link, {
        [classes.linkActive]: item.link === `${'/' + location.pathname.split('/')[1]}`
      })}
    >
      {item.label}
    </Link>
  ));

  return (
    <Group
      h={height}
      className={cx(classes.root, {
        [classes.dashboard]: dashboardPage
      })}
    >
      <Box className={classes.inner}>
        <Group>
          {isAdmin(currentUser) && !dashboardPage && (
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
          )}
          <Box className={classes.logo}>
            {dashboardPage ? (
              <img
                src={logoCrudoc}
                className={classes.logo}
              />
            ) : (
              <Link to="/">
                <img src={logoCrudoc} alt="" height={30} />
              </Link>
            )}
          </Box>
        </Group>

        {isAdmin(currentUser) && !dashboardPage && (
          <Box className={classes.links}>
            <Group gap={0} justify="flex-start" className={classes.linksGroup}>
              {mainItems}
            </Group>
          </Box>
        )}

        {Boolean(rightSection) && rightSection}
        <Transition transition="pop-top-left" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {mainItems}
            </Paper>
          )}
        </Transition>
      </Box>
    </Group>
  );
};
