import { ChangePasswordModal } from '@/containers/pages/user/components/sections/linked-emails/modals/changePasswordModal';
import { CreateEmailAccount } from '@/containers/pages/user/components/sections/linked-emails/modals/createEmailAccount';
import { DeleteEmailModal } from '@/containers/pages/user/components/sections/linked-emails/modals/deleteEmailModal';
import { LinkEmailModal } from '@/containers/pages/user/components/sections/linked-emails/modals/linkEmailModal';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { useAppSelector } from '@/hooks/redux/redux';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { isAdmin } from '@/lib/utils/access';
import { themeColors } from '@/theme/colors';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { ActionIcon, Box, Button, SimpleGrid, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconCircleX, IconPlus } from '@tabler/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface ILinkedEmailsProps {
  form: UseFormReturnType<any>;
  sectionFormKey: string;
  listLinkedEmails: ILinkedEmail[] | null;
  isCreateUserPage?: boolean;
  filialId?: number | null;
  currentId?: number;
  editUser: boolean;
}
export const LinkedListEmails = ({
  form,
  sectionFormKey,
  listLinkedEmails,
  filialId,
  isCreateUserPage,
  currentId,
  editUser
}: ILinkedEmailsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [
    isOpenedChangePasswordModal,
    { open: openChangePasswordModal, close: closeChangePasswordModal }
  ] = useDisclosure(false);
  const [isOpenedDeleteEmailModal, { open: openDeleteEmailModal, close: closeDeleteEmailModal }] =
    useDisclosure(false);
  const [isOpenedCreateModal, { open: openCreateModal, close: closeCreateModal }] =
    useDisclosure(false);
  const [selectedEmail, setSelectedEmail] = useState<ILinkedEmail | null>(null);
  const currentUser = useAppSelector((state) => state.userInfo.User);

  const { saveUpdate, isLoading, isSuccess } = useUpdateInfo();

  const handleAddEmail = async (email: ILinkedEmail) => {
    form?.insertListItem(`${sectionFormKey}`, { ...email });
    if (editUser && currentId) {
      await saveUpdate({
        Type: ProfileUpdateTypeEnum.Email,
        Data: { [ProfileSectionEnum.LinkedEmail]: [email], Id: currentId }
      });
    }
  };

  const changePassword = (email: ILinkedEmail) => {
    setSelectedEmail(email);
    openChangePasswordModal();
  };
  const deleteEmailItem = (email: ILinkedEmail) => {
    setSelectedEmail(email);
    openDeleteEmailModal();
  };

  const closeDeleteModal = () => {
    setSelectedEmail(null);
    closeDeleteEmailModal();
  };

  const closePasswordModal = () => {
    setSelectedEmail(null);
    closeChangePasswordModal();
  };

  return (
    <>
      {listLinkedEmails?.length
        ? listLinkedEmails?.map((linkedEmail, index) => {
            return linkedEmail.Email ? (
              <SimpleGrid className={classes.listRow} key={index} cols={{ base: 1, xs: 3 }}>
                <TextInput readOnly label={t('profile.account')} value={linkedEmail.Email} />
                {linkedEmail.Id === 0 || !linkedEmail.IsIntegrated ? null : (
                  <Button
                    mt={24}
                    className={classes.changePasswordButton}
                    size="sm"
                    variant="outline"
                    onClick={() => changePassword(linkedEmail)}
                  >
                    {t('profile.changePassword')}
                  </Button>
                )}
                <Box className={classes.removeButton}>
                  <ActionIcon onClick={() => deleteEmailItem(linkedEmail)}>
                    <IconCircleX size={32} color="#667085" />
                  </ActionIcon>
                </Box>
              </SimpleGrid>
            ) : null;
          })
        : null}

      <>
        {!listLinkedEmails?.length ? (
          <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
            <Button
              className={classes.addBtn}
              size="sm"
              variant="outline"
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => open()}
            >
              {t('profile.linkAccount')}
            </Button>
          </SimpleGrid>
        ) : null}

        {(!listLinkedEmails?.length && isCreateUserPage) ||
        (isAdmin(currentUser) && editUser && !listLinkedEmails?.length) ? (
          <SimpleGrid cols={{ base: 1, xs: 2 }} mt={16} w="100%">
            <Button
              className={classes.addBtn}
              size="sm"
              variant="outline"
              disabled={!filialId}
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => openCreateModal()}
            >
              {t('profile.createNewAccount')}
            </Button>
          </SimpleGrid>
        ) : null}

        <Text mt={16} fz={14} c="#667085">
          {t('profile.infoDescription')}
        </Text>
      </>

      <LinkEmailModal
        opened={opened}
        handleClose={close}
        handleAddEmail={handleAddEmail}
        filialId={filialId}
        isLoading={isLoading}
        isSuccess={isSuccess}
        editUser={editUser}
      />

      <ChangePasswordModal
        selectedEmail={selectedEmail}
        opened={isOpenedChangePasswordModal}
        handleClose={closePasswordModal}
      />
      <DeleteEmailModal
        selectedEmail={selectedEmail}
        opened={isOpenedDeleteEmailModal}
        handleClose={closeDeleteModal}
        editUser
        currentId={currentId}
        form={form}
        sectionFormKey={sectionFormKey}
      />

      {isOpenedCreateModal && filialId ? (
        <CreateEmailAccount
          handleClose={closeCreateModal}
          opened={isOpenedCreateModal}
          handleAddEmail={handleAddEmail}
          filialId={filialId}
        />
      ) : null}
    </>
  );
};
