import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles((theme, _, utils) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,
  container: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0]
  },

  list: {
    width: 'calc(100vw - 264px)'
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  searchWrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    padding: '0 24px 0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0 20px 0'
  },

  createButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: theme.spacing.xs,
    marginLeft: '27px',
    span: {
      height: 'auto',
      marginRight: '8px'
    },
    [utils.smallerThan('lg')]: {
      padding: '15px',
      justifyContent: 'center',
      span: {
        margin: '0'
      }
    }
  },

  createButtonText: {
    [utils.smallerThan('lg')]: {
      display: 'none'
    }
  },
  search: {
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    },
    '& .mantine-Input-section': {
      marginLeft: '15px'
    }
  },

  avatar: {
    cursor: 'pointer'
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  simpleNavbar: {
    // marginTop: '54px',
    backgroundColor: theme.colors.backgroundGray[0],
    position: 'fixed',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    borderRight: 'none',
    flexShrink: 0,

    [utils.smallerThan('md')]: {
      // width: '55px',
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      backgroundColor: 'white'
    }
  },

  burgerMenu: {
    marginRight: '20px',
    [utils.largerThan('md')]: {
      display: 'none'
    }
  },

  headerMenu: {
    paddingLeft: '25px',
    [utils.largerThan('md')]: {
      paddingLeft: '10px'
    },
    maxWidth: '800px'
  },

  titleContainer: {
    wordBreak: 'break-all'
  },

  infoSection: {
    marginTop: '54px',
    position: 'fixed',
    right: 0,
    left: 'auto',
    borderRight: 'none',
    flexShrink: 0,
    backgroundColor: theme.colors.backgroundGray[0],
    '@media (max-width: 1200px)': {
      display: 'none',
      width: '0'
    }
  },

  preserveSpaces: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },

  wrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    height: '100%',
    [utils.largerThan('md')]: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      marginLeft: 290,
      marginRight: 290,
      boxSizing: 'border-box'
    },
    '@media (max-width: 1200px)': {
      marginRight: 0
    }
  },

  section: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  }
}));
